body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiTableCell-head {
  background-color: #272727 !important;
  color: white !important;
  font-size: 12px !important;
  text-align: center !important;
  overflow-x: auto;
}
.MuiTableCell-body {
  background-color: #343434 !important;
  color: white !important;
  font-size: 12px !important;
  text-align: center !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-input,
.MuiTablePagination-displayedRows,
.MuiInputLabel-formControl {
  font-size: 16px !important;
}
.MuiTablePagination-toolbar {
  background-color: #343434 !important;
  color: white;
}

.text-center {
  text-align: center;
}
.text-sm {
  font-size: 12px;
}
.custom-pill {
  width: 100px;
  text-align: center;
  font-size: 14px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  border-radius: 10px;
}
.active-pill {
  background-color: white;
  color: black;
  border-radius: 24px;
  width: fit-content;
  padding: 6px 12px 6px 12px;
  margin: 6px;
  font-size: 12px;
  height: fit-content;
}
.settled-pill {
  background-color: #d6fae3;
  color: #0f7537;
}
.class-certified-pill {
  background-color: #faf1de;
  color: #835f16;
}
.dismissed-pill {
  background-color: #f9e5e9;
  color: #c65d6a;
}
.font-bold {
  font-weight: "bold";
}
.filter-btn {
  width: 100px;
  height: 40px;
  background-color: white !important;
  color: black !important;
  cursor: pointer;
  margin-right: 12px !important;
  font-size: 12px !important;
}
.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #343434 !important;
  padding-top: 12px;
  padding-bottom: 12px;
}
.text-lg {
  font-size: 22px;
}
.text-base {
  font-size: 16px;
  margin-bottom: 12px;
}
.MuiAutocomplete-input {
  font-size: 16px !important;
  color: white !important;
}
.MuiAutocomplete-inputRoot {
  border: 1px solid white;
  margin-top: 10px;
}

.text-xs {
  font-size: 14px;
}
.mt-12 {
  margin-top: 36px;
}
.text-xl {
  font-size: 18px;
}
.text-white {
  color: white !important;
}
.bg-black {
  background-color: #282828 !important;
}
.bg-black:hover {
  background-color: #453f3d !important;
}
.MuiSwitch-root {
  margin-left: -10px !important;
}
.apply-filter-btn {
  position: absolute !important;
  right: 90px;
  top: 25px;
  width: 100px;
  height: 40px;
  background-color: #007bff !important;
  border-color: #007bff;
  color: white !important;
  cursor: pointer;
  display: block !important;
  font-size: 14px !important;
  border-radius: 20px !important;
}

.action-btn {
  background-color: #007bff;
  border-color: #007bff;
  color: white !important;
  cursor: pointer;
  display: block !important;
  font-size: 14px !important;
  border-radius: 20px !important;
}

.update-btn {
  width: 140px;
  height: 50px;
  background-color: #007bff !important;
  border-color: #007bff;
  color: white !important;
  cursor: pointer;
  display: block !important;
  font-size: 15px !important;
  margin-top: 30px !important;
  margin-bottom: 10px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.MuiInputBase-inputAdornedEnd {
  color: white !important;
  font-size: 14px !important;
}

.MuiIconButton-edgeEnd {
  /* color: white !important; */
}
.date-picker-container {
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 12px;
  margin-right: 16px;
  margin-left: -9px;
  width: 156px;
}

.flex {
  display: flex;
  flex-direction: row;
}
.cursor-pointer {
  cursor: pointer !important;
}
.close-icon {
  font-size: 12px !important ;
  margin-left: 6px;
  margin-top: 2px;
}
.MuiAutocomplete-tagSizeMedium {
  background-color: white !important;
}
.MuiButtonBase-root.upload-csv-btn {
  width: 120px;
  height: 40px;
  color: white;
  border: 1px solid white;
  cursor: pointer;
  margin-right: 12px;
  font-size: 12px;
  border: 1px solid white;
}

.MuiTypography-body1 {
  font-size: 16px !important;
}
.my-6 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.m-16 {
  margin: 6px 16px 0px 16px !important;
}

.mt-4 {
  margin-top: 8px !important;
}
.w-28 {
  width: 280px !important;
}
